import styled from "styled-components";
import { device } from "./device";

interface HeadlineProps {
  dark?: boolean;
}

export const Headline = styled.h2<HeadlineProps>`
  color: ${(props) => props.dark ? props.theme.colors.fontWhite : props.theme.colors.fontBlack};
  font-size: 40px;

  span {
    color: ${(props) => props.theme.colors.primary};
  }

  @media ${device.tablet} {
    font-size: 48px;
  }
`;
export const SubHeadline = styled.h3<HeadlineProps>`
  color: ${(props) => props.dark ? props.theme.colors.fontWhite : props.theme.colors.fontBlack};
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
  margin: 0;
`;
