import Image from 'next/image';
import React from 'react';
import styled from 'styled-components';
import { Container, ContainerContentStandard } from '../../../../shared/styles/container';
import { device } from '../../../../shared/styles/device';

const MediaSectionContainer = styled.section`
  padding-top: 128px;
  padding-bottom: 128px;
  h2 {
    margin-bottom: 8px;
  }
  h3 {
    margin-bottom: 48px;
    font-size: 18px;
    font-weight: 500;
    line-height: 32px;
    margin: 0px;
  }
`;
const MediaGrid = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);

  @media ${device.tablet} {
    grid-template-columns: minmax(0, 1fr) minmax(0, 2fr);
    grid-column-gap: 32px;
  }
`;
const MediaImageRow = styled.div`
  width: 100%;
  overflow: hidden;
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  align-items: center;
  justify-content: center;
  padding: 32px 0;

  .gatsby-image-wrapper {
    margin: auto;
  }


  @media ${device.tablet} {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    grid-column-gap: 32px;
    grid-row-gap: 32px;
    padding: 0;
  }
`;
const MediaImageItem = styled.div`
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    min-height: 80px;
`
const MediaTextColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 32px;

  @media ${device.tablet} {
    margin-bottom: 0;
  }

  h2 {
    margin-top: 0;
    margin-bottom: 8px;
  }
`;

export const MediaSection = () => {
  return (
    <MediaSectionContainer>
      <Container>
        <ContainerContentStandard>
          <MediaGrid>
            <MediaTextColumn>
              <h2>Bekannt aus <br /> den Medien</h2>
              <h3>Diese Medien haben bereits über das STELL-MICH-EIN berichtet.</h3>
            </MediaTextColumn>
            <MediaImageRow>
              <MediaImageItem>
                <Image
                  src="/images/medien/STELL-MICH-EIN-Medien1.jpg"
                  alt="Aus den Medien 1"
                  layout='fill'
                  quality={100}
                  objectFit='contain'
                />
              </MediaImageItem>

              <MediaImageItem>
                <Image
                  src="/images/medien/STELL-MICH-EIN-Medien2.jpg"
                  alt="Aus den Medien 2"
                  layout='fill'
                  quality={100}
                  objectFit="contain"
                />
              </MediaImageItem>

              <MediaImageItem>
                <Image
                  src="/images/medien/STELL-MICH-EIN-Medien3.jpg"
                  alt="Aus den Medien 3"
                  layout='fill'
                  quality={100}
                  objectFit="contain"
                />
              </MediaImageItem>

              <MediaImageItem>
                <Image
                  src="/images/medien/STELL-MICH-EIN-Medien4.jpg"
                  alt="Aus den Medien 4"
                  layout='fill'
                  quality={100}
                  objectFit="contain"
                />
              </MediaImageItem>

              <MediaImageItem>
                <Image
                  src="/images/medien/STELL-MICH-EIN-Medien5.jpg"
                  alt="Aus den Medien 5"
                  layout='fill'
                  quality={100}
                  loading="eager"
                  objectFit="contain"
                />
              </MediaImageItem>

              <MediaImageItem>
                <Image
                  src="/images/medien/STELL-MICH-EIN-Medien6.jpg"
                  alt="Aus den Medien 6"
                  layout='fill'
                  quality={100}
                  objectFit="contain"
                />
              </MediaImageItem>
            </MediaImageRow>
          </MediaGrid>
        </ContainerContentStandard>
      </Container>
    </MediaSectionContainer>
  )
}
